import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { Button, Dropdown, DropdownButton} from 'react-bootstrap';



const LanguageSelect = () => {
    const { t } = useTranslation();
    const languageMap = {
        en: { label: t('languages.en'), active: true },
        pt: { label: t('languages.pt'), active: false },
    };
  const selected = localStorage.getItem("i18nextLng") || "en";
  

  return (
    <div>
        <DropdownButton
            className="float-right"
            size='lg'
            variant="outline-dark"
            title={languageMap[selected].label}
            id="input-group-dropdown-1"
        >
            {Object.keys(languageMap).map(item => {
                return (
                <Dropdown.Item href="#"
                    key={item}
                    onClick={() => {
                      i18next.changeLanguage(item);
                      
                    }}
                    >
                    {languageMap[item].label}
                </Dropdown.Item>
                );
            })}
        </DropdownButton>
    </div>
  );
};

export default LanguageSelect;
