import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import app from "./base.js";
import { AuthContext } from "./Auth.js";
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import LanguageSelect from "./languageSelect.js";

const Login = ({ history }) => {
  const { t } = useTranslation();

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    
      <div className="">
        <Row className="header">
          <Col sm={8} xs={12}>
            <h1>Sincronário 13:20 - {t('subscriptions')}</h1>
          </Col>
          <Col sm={4} xs={12}>
            <LanguageSelect/>
          </Col>
        </Row>
        <div style={{height: "90px"}}></div>
        <Container>
          <Row>
          <Col></Col>
          <Col className="login" xs={12} md={4}>
            <h1>Login</h1>
            <Form onSubmit={handleLogin}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" type="email" placeholder={t('login.email')} />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>{t('login.pass.name')}</Form.Label>
              <Form.Control name="password" type="password" placeholder={t('login.pass.hint')} />
            </Form.Group>
            <Row>
              <Col>
                <Button variant="link" size="sm" className="float-right" href="/rememberPass">{t('rememberPass.ask')}</Button>
              </Col>
            </Row>
            <div className="d-grid gap-2">
              <Button variant="outline-success" type="submit">{t('login.btn')}</Button>
            </div>
            </Form>
          </Col>
          <Col></Col>
        </Row>
        </Container>
      </div>
    
  );
};

export default withRouter(Login);
