import React from "react";
import app from "./base";
import {Button, Card, Row, Col} from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import { withTranslation } from "react-i18next";
import LanguageSelect from "./languageSelect";
import axios from 'axios';

class Home extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [],
      isGalactic: false,
      isChrononaut: false,
      loadingManagement: false,
      loadingAssign: false,
      loadingCoffe: false,
      local: null,
      price: {
        galactic: null,
        coffe: null,
        chrononaut: null
      },
      products: {
        galactic: 'prod_JbAbpZ2acU977z',
        chrononaut: 'prod_KvKn6sHD37ft6N'
      }
    };
    
    this.getPrice();
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const currentUser = app.auth().currentUser;
    // currentUser.getIdToken();
    // app.auth().signInWithCustomToken();
  
    const customer = await app.firestore().collection('customers')
      .doc(currentUser.uid);
    
      customer.onSnapshot(async (snap) => {
        if (!snap.exists) {
          const checkoutSession = {
            collect_shipping_address: false,
            tax_rates: [],
            allow_promotion_codes: false,
            line_items: [{"price": 'price_1J3Wp7Cba12tZTe9gmrniWp5', "quantity": 1}],
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            metadata: {
              key: 'value',
            },
            mode: 'payment',
            locale: 'auto'
          };
        
          const docRef = await app.firestore()
            .collection('customers')
            .doc(currentUser.uid)
            .collection('checkout_sessions')
            .add(checkoutSession);
            return;
        }
      });

      const ref = customer
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active']);
  
    return ref
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          return;
        }
        var d = snapshot.docs[0].data()
        this.setState({
          subscriptions: d,
          isGalactic: ((d.status === 'active') && (d.items[0].price.product.id === 'prod_JbAbpZ2acU977z')),
          isChrononaut: ((d.status === 'active') && (d.items[0].price.product.id === 'prod_KvKn6sHD37ft6N'))
        });
        return this.state.subscriptions;
      });
  }

  async assignChrononautPlan() {
    this.setState({
      loadingAssign: true
    });

    const currentUser = app.auth().currentUser;
  
    const checkoutSession = {
      collect_shipping_address: false,
      tax_rates: [],
      allow_promotion_codes: false,
      line_items: [{"price": this.state.price.chrononaut.id, "quantity": 1}],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      locale: 'auto'
    };
  
    const docRef = await app.firestore()
      .collection('customers')
      .doc(currentUser.uid)
      .collection('checkout_sessions')
      .add(checkoutSession);
  
    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();

      if (error) {
        // Show an error to your customer and 
        // inspect your Cloud Function logs in the Firebase console.
        alert(this.props.t('error'));
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await loadStripe('pk_live_51IvoQYCba12tZTe9HYGHchSV9Ez8fvtRcs6XAoDlx6rPwc54CdEUOq38r6azGmwCeUjuDwOAkwrNZn0za5YfLD81006FiMxioT');

        stripe.redirectToCheckout({ sessionId });
        
        this.setState({
          loadingAssign: false
        });
      }
    });
  }

  async assignGalacticPlan() {
    this.setState({
      loadingAssign: true
    });

    const currentUser = app.auth().currentUser;
  
    const checkoutSession = {
      collect_shipping_address: false,
      tax_rates: [],
      allow_promotion_codes: false,
      line_items: [{"price": this.state.price.galactic.id, "quantity": 1}],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      locale: 'auto'
    };
  
    const docRef = await app.firestore()
      .collection('customers')
      .doc(currentUser.uid)
      .collection('checkout_sessions')
      .add(checkoutSession);

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();

      if (error) {
        // Show an error to your customer and 
        // inspect your Cloud Function logs in the Firebase console.
        alert(this.props.t('error'));
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await loadStripe('pk_live_51IvoQYCba12tZTe9HYGHchSV9Ez8fvtRcs6XAoDlx6rPwc54CdEUOq38r6azGmwCeUjuDwOAkwrNZn0za5YfLD81006FiMxioT');
        stripe.redirectToCheckout({ sessionId });

        this.setState({
          loadingAssign: false
        });
      }
    });
  }

  async buyCoffe() {
    this.setState({
      loadingCoffe: true
    });

    const currentUser = app.auth().currentUser;
    
    const checkoutSession = {
      collect_shipping_address: false,
      tax_rates: [],
      allow_promotion_codes: false,
      line_items: [{"price": this.state.price.coffe.id, "quantity": 1}],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      metadata: {
        key: 'value',
      },
      mode: 'payment',
      locale: 'auto'
    };
  
    const docRef = await app.firestore()
      .collection('customers')
      .doc(currentUser.uid)
      .collection('checkout_sessions')
      .add(checkoutSession);
  
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
  
        if (error) {
          // Show an error to your customer and 
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe('pk_live_51IvoQYCba12tZTe9HYGHchSV9Ez8fvtRcs6XAoDlx6rPwc54CdEUOq38r6azGmwCeUjuDwOAkwrNZn0za5YfLD81006FiMxioT');
  
          // Undefined redirectToCheckout on npm stripe
          stripe.redirectToCheckout({ sessionId });
        }
        this.setState({
          loadingCoffe: false
        });
      });
  }

  async getPrice() {
    axios({
      method: 'POST',
      url:'https://us-central1-sincronario1320-461b0.cloudfunctions.net/api2',
    }).then(response => {
      this.setState({
        price: response.data
      })
    }).catch(err => {
      // Mensagem de erro.
      console.log('err', err)
    });
  }
  
  async manageSubs() {
    var functionRef = app
      .functions("us-central1")
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
      
    this.setState({
      loadingManagement: true
    });
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
    this.setState({
      loadingManagement: false
    });
  }

  render() {
    return (
      <div className="all">
        <Row className="header">
          <Col md={8} xs={12}>
            <h1>Sincronário 13:20 - {this.props.t('subscriptions')}</h1>
          </Col>
          <Col md={2} xs={6}>
            <LanguageSelect/>
          </Col>
          <Col md={2} xs={6}>
            <Button className="float-right" size="lg" variant="dark" onClick={() => app.auth().signOut()}>
              {this.props.t('logout')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Card className="product">
              <Card.Body>
                <Card.Title>{this.props.t('chrononaut_plan.name')} {(this.state.price.chrononaut != null) ? '- ' + this.state.price.chrononaut.value : ''}</Card.Title>
                <span>{this.props.t('chrononaut_plan.desc')}</span>
                <Card.Text>
                  {this.props.t('chrononaut_plan.resources.0')}<br/>
                  {this.props.t('chrononaut_plan.resources.1')}<br/>
                  {this.props.t('chrononaut_plan.resources.2')}<br/>
                </Card.Text>
                <div className="d-grid gap-2">
                  {(this.state.isChrononaut || this.state.isGalactic)
                  ? <Button 
                        disabled={this.state.loadingManagement}
                        size="lg"
                        variant="outline-primary"
                        onClick={() => this.manageSubs()}
                    >{(this.state.loadingManagement ? this.props.t('loading') : this.props.t('manage'))}</Button>
                  : <Button
                    disabled={this.state.loadingAssign}
                    size="lg"
                    variant="outline-success"
                    onClick={() => this.assignChrononautPlan()}>
                      {(this.state.loadingAssign) ? this.props.t('loading') : this.props.t('subscribe')}
                    </Button>}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="product">
              <Card.Body>
                <Card.Title>{this.props.t('galactic_plan.name')} {(this.state.price.galactic != null) ? '- ' + this.state.price.galactic.value : ''}</Card.Title>
                <span>{this.props.t('galactic_plan.desc')}</span>
                <Card.Text>
                  {this.props.t('galactic_plan.resources.0')}<br/>
                  {this.props.t('galactic_plan.resources.1')}<br/>
                  {this.props.t('galactic_plan.resources.2')}<br/>
                  {this.props.t('galactic_plan.resources.3')}<br/>
                  {this.props.t('galactic_plan.resources.4')}<br/>
                  {this.props.t('galactic_plan.resources.5')}<br/>
                </Card.Text>
                <div className="d-grid gap-2">
                  {!this.state.isGalactic
                  ? <Button
                      disabled={this.state.loadingAssign}
                      size="lg"
                      variant="outline-success"
                      onClick={() => this.assignGalacticPlan()}>
                        {(this.state.loadingAssign) ? this.props.t('loading') : this.props.t('subscribe')}
                      </Button>
                  : <Button 
                    disabled={this.state.loadingManagement}
                    size="lg"
                    variant="outline-primary"
                    onClick={() => this.manageSubs()}
                >{(this.state.loadingManagement ? this.props.t('loading') : this.props.t('manage'))}</Button>}
                  
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4}>
            <Card className="product">
              <Card.Body>
                <Card.Title>{this.props.t('payMeACoffe.name')}</Card.Title>
                <Card.Text>
                {this.props.t('payMeACoffe.desc')}
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button 
                    disabled={this.state.loadingCoffe}
                    size="lg"
                    variant="outline-success"
                    onClick={() => this.buyCoffe()}>{this.state.loadingCoffe ? this.props.t('loading') : this.props.t('buy')}</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4}>
            <Card className="product">
              <Card.Body>
                <Card.Title>{this.props.t('pix.name')}</Card.Title>
                <Card.Text style={{'whiteSpace': 'pre-line'}}>
                {this.props.t('pix.desc')}
                
                </Card.Text>
                <p>PIX: chuen.can.251@gmail.com</p>
              </Card.Body>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </div>
    );
  } 
}

export default withTranslation()(Home);
