import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import app from "./base.js";
import { AuthContext } from "./Auth.js";
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import LanguageSelect from "./languageSelect.js";

const RememberPass = ({ history }) => {
  const { t } = useTranslation();

  const handleRemember = useCallback(
    async event => {
      event.preventDefault();
      const { email } = event.target.elements;
      try {
        await app
          .auth()
          .sendPasswordResetEmail(email.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  return (
      <div className="">
        <Row className="header">
          <Col md={8} xs={6}>
            <h1>Sincronário 13:20 - {t('subscriptions')}</h1>
          </Col>
          <Col md={4} xs={6}>
            <LanguageSelect/>
          </Col>
        </Row>
        <div style={{height: "90px"}}></div>
        <Container>
          <Row>
          <Col></Col>
          <Col className="remember" xs={12} md={4}>
            <h1>{t('rememberPass.title')}</h1>
            <Form onSubmit={handleRemember}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" type="email" placeholder={t('login.email')} />
            </Form.Group>
            <div style={{"height": "20px"}}></div>
            <div className="d-grid gap-2">
              <Button variant="outline-success" type="submit">{t('rememberPass.btn')}</Button>
            </div>
            <div style={{"height": "10px"}}></div>
            <div className="d-grid gap-2">
              <Button variant="outline-dark" href="/login">{t('back')}</Button>
            </div>
            </Form>
          </Col>
          <Col></Col>
        </Row>
        </Container>
      </div>
  );
};

export default withRouter(RememberPass);
